import React from 'react'
import PropTypes from 'prop-types'

const Card = props => {

    const { children, type, style, className, onClick, innerSpacing, handleClick, ...restOfProps } = props;


    // Card Class
    const typeClasses = type === "primary" ? "bg-white text-primary border-2 border-primary" : "bg-white text-primary border-2 border-secondary"
    const cardClasses = `leading-loose text-left flex-auto flex-wrap justify-between bg-white p-${innerSpacing}`


    return (
        <div
            {...restOfProps}
            className={`${typeClasses} ${cardClasses} ${className}`}
            style={style}
        >
            {children}
        </div>
    )
}

Card.defaultProps = {
    type: "primary",
    innerSpacing: 8,
}

Card.propTypes = {
    children: PropTypes.node,
    type: PropTypes.oneOf(["primary", "secondary"]),
    className: PropTypes.string,
    style: PropTypes.object,
    innerSpacing: PropTypes.number,
}

export default Card
