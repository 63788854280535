import { rand } from './Utils'

function calculateRotation(rotate) {
    if (rotate) return { transform: 'rotate(' + rand(300, 60, "deg") + ")" }
    return {}
}
export const drift = (floatStyle, rotate) => ({
    config: floatStyle,
    to: [{ top: '10%', left: '50%', ...calculateRotation(rotate) },
    { top: '35%', left: '75%', ...calculateRotation(rotate) },
    { top: '65%', left: '90%', ...calculateRotation(rotate) },
    { top: '100%', left: '100%', ...calculateRotation(rotate) },
    { top: '88%', left: '60%', ...calculateRotation(rotate) },
    { top: '30%', left: '20%', ...calculateRotation(rotate) },
    { top: '45%', left: '45%', transform: 'rotate(0deg)' }],
    from: { top: '45%', left: '45%', transform: 'rotate(0deg)' }
})


export const wobbleDrift = (floatStyle, rotate) => ({
    config: floatStyle,
    to: [{ top: rand(30, 35, '%'), left: rand(30, 35, '%'), ...calculateRotation(rotate) },
    { top: rand(30, 35, '%'), left: rand(30, 35, '%'), ...calculateRotation(rotate) },
    { top: rand(10, 35, '%'), left: rand(30, 35, '%'), ...calculateRotation(rotate) },
    { top: rand(40, 25, '%'), left: rand(40, 25, '%'), ...calculateRotation(rotate) },
    { top: rand(10, 35, '%'), left: rand(10, 35, '%'), ...calculateRotation(rotate) },
    { top: rand(30, 35, '%'), left: rand(30, 35, '%'), ...calculateRotation(rotate) },
    { top: '45%', left: '45%', transform: 'rotate(0deg)' }],
    from: { top: '45%', left: '45%', transform: 'rotate(0deg)' }
})
export const largeWobbleDrift = (floatStyle, rotate) => ({
    config: floatStyle,
    to: [{ top: rand(50, 20, '%'), left: rand(45, 15, '%'), ...calculateRotation(rotate) },
    { top: rand(150, 25, '%'), left: rand(50, 35, '%'), ...calculateRotation(rotate) },
    { top: rand(10, 10, '%'), left: rand(30, 35, '%'), ...calculateRotation(rotate) },
    { top: rand(40, 10, '%'), left: rand(15, 25, '%'), ...calculateRotation(rotate) },
    { top: rand(20, 60, '%'), left: rand(10, 60, '%'), ...calculateRotation(rotate) },
    { top: rand(30, 35, '%'), left: rand(30, 35, '%'), ...calculateRotation(rotate) },
    { top: '45%', left: '45%', transform: 'rotate(0deg)' }],
    from: { top: '45%', left: '45%', transform: 'rotate(0deg)' }
})

export const yoyo = (floatStyle, rotate) => ({
    config: floatStyle,
    to: [
        { top: rand(20, 80, '%'), left: rand(80, 20, '%'), ...calculateRotation(rotate) },
        { top: '50%', left: '50%', transform: 'rotate(0deg)' }
    ],
    from: { top: '50%', left: '50%', transform: 'rotate(0deg)' }
})


export const spin = (floatStyle) => ({
    config: floatStyle,
    to: [
        { transform: 'rotate(180deg)', },
        { transform: 'rotate(360deg)' }
    ],
    from: { transform: 'rotate(0deg)' }
})



export const floatTypes = {
    drift,
    yoyo, 
    wobbleDrift,
    spin, 
    largeWobbleDrift
}   