import React, { useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import { useSpring } from 'react-spring'
import { floatTypes } from '../../functions/FloatsTypes'





const speedSettings = (custom) => ({
    slow: 2000,
    medium: 1500,
    fast: 1000,
    custom: custom,
})



const FloatingContainer = props => {


    const { style,
        className,
        front,
        back,
        width,
        height,
        delay,
        speed,
        floatStyle,
        floatSpeed,
        floatType,
        rotate,
        children,
        ...restProps } = props;


    const floatStyles = {
        linear: { mass: 1, tension: 10, friction: 40, delay: delay, duration: speedSettings(speed)[floatSpeed] },
        spring: { mass: 20, tension: 200, friction: 20, delay },

    }


    // Make the animation infinite
    const [running, setRunning] = useState(true); // 0 reset, 1 run


    useEffect(() => {
        
        if (!running) { setRunning(true) }

        return setRunning(true)
    }, [running])


    const chosenFloatStyle = floatStyles[floatStyle]
    const chosenFloatType = floatTypes[floatType](chosenFloatStyle, rotate)

    const floatObj = useSpring({
        ...chosenFloatType,
        reset: !running,
        onRest: () => setRunning(false)
    })


    return (
        <div 
        className="relative">
            <div
                style={{ ...style, height, width, zIndex: front ? "9999" : "-1" }}
                className={`absolute ${className}`}
                {...restProps}
            >
                {React.Children.map(children, (element, idx) => {
                    return React.cloneElement(element,
                        {
                            style: floatObj,
                        });
                })}
            </div>

        </div>

    )
}

FloatingContainer.defaultProps = {
    className: "",
    height: "",
    width: "",
    floatSpeed: 'medium',
    floatStyle: 'linear',
    floatType: 'drift',
    rotate: true,
    delay: 0,
}
FloatingContainer.propTypes = {
    className: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    floatSpeed: PropTypes.string,
    floatStyle: PropTypes.string,
    floatType: PropTypes.string,
    rotate: PropTypes.bool,
    delay: PropTypes.number,
    children: PropTypes.node.isRequired
}

export default FloatingContainer
