// To calculate the break point on a users window
export const findBreakPoint = (widthOfWindow) => {
    if (widthOfWindow < 640) return 1
    if (widthOfWindow < 768) return 2
    if (widthOfWindow < 1024) return 3
    if (widthOfWindow >= 1024) return 4
  }


  // Random generator that can return a string and has a base value
  export function rand(rand, base = 0, append = "") {
    const randomValue = base + (Math.random() * rand);
    return randomValue + append
}
