import React from 'react'
import PropTypes from 'prop-types'
import { animated } from 'react-spring'
// Shapes
import circle from '../../images/ui/floating/circle.svg'
import halfMoon from '../../images/ui/floating/half-moon.svg'
import triangle from '../../images/ui/floating/triangle.svg'
import polygon from '../../images/ui/floating/polygon.svg'



const shapes = {
    circle,
    halfMoon,
    triangle,
    polygon
}


const FloatingShape = props => {
    const { shape, height, width, style, className, ...restProps } = props;
    return (
        <animated.img 
            style={{...style, height, width}}
            className={`relative ${className}`}
            src={ shapes[shape] } 
            alt={`Floating ${shape}`}
            {...restProps}
            />
    )
}

FloatingShape.defaultProps = {
    shape: "circle",
    height: 60,
    width: 60,
    className: ""
}
FloatingShape.propTypes = {
    shape: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    className: PropTypes.string
}

export default FloatingShape
