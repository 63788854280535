import React from 'react'
import PropTypes from 'prop-types'
import { useSpring } from 'react-spring'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Arrow from '../../images/ui/arrow.svg'

const ArticleNavigation = ({ currentArticle }) => {

    // Handles scroll to the top
    const [, setY] = useSpring(() => ({ y: 0 }))

    // Fetches the data on articles
    const data = useStaticQuery(graphql`
     query ArticleSlugs {
        allContentfulKnowledgeHubArticles {
            edges {
                node {
                    id
                    slug
                }
            }
        }
     }
   `)

    // Create article array and reduce to array of article ids
    const articleArray = data.allContentfulKnowledgeHubArticles.edges
    const articleIds = articleArray ? articleArray.map(article => article.node.id) : []

    // Find current article Index
    const currentIndex = articleIds.indexOf(currentArticle)


    // Assign indexes
    const previousArticleId = (currentIndex === 0 ? articleIds.length - 1 : currentIndex - 1)
    const nextArticleId = (currentIndex === articleIds.length - 1 ? 0 : currentIndex + 1)


    // Find articles according to indexes
    const nextArticle = `/articles/${articleArray[nextArticleId].node.slug}`
    const previousArticle = `/articles/${articleArray[previousArticleId].node.slug}`





    return (
        <div className="flex justify-between lg:w-2/3 mx-auto mb-24 px-6 lg:px-0" >

            <Link
                className="w-1/3 flex"
                to={previousArticle}
            >
                <div
                    style={{}}
                    className=" z-999 left-4 lg:left-8 bg-secondary border-2 border-primary h-16 w-16 flex flex-col justify-center text-center rounded-full hover:shadow-2xl cursor-pointer transition"
                >
                    <img
                        className="mx-auto"
                        style={{ transform: 'scale(1.5) rotate(-90deg)' }}
                        src={Arrow}
                        alt="Navigate Left" />
                </div>
                <p className="self-center mb-0 pl-4 hidden lg:inline-block">PREVIOUS ARTICLE</p>
            </Link>

            <div
                className="self-center w-1/3 text-center hover:underline text-lg"
                onClick={() => {
                    setY({
                        y: 0,
                        reset: true,
                        from: { y: window.scrollY },
                        onFrame: props => window.scroll(0, props.y)
                    })
                }}
            >
                BACK TO TOP
            </div>

            <Link
                className="w-1/3 flex"
                to={nextArticle}
            >
                <p className="self-center mb-0 pr-4 ml-auto hidden lg:inline-block">NEXT ARTICLE</p>
                <div
                    className="ml-auto lg:ml-0 z-999 md:right-4 lg:right-8 bg-secondary border-2 border-primary h-16 w-16 flex flex-col justify-center text-center rounded-full hover:shadow-2xl cursor-pointer transition"
                >
                    <img className="mx-auto"
                        style={{ transform: 'scale(1.5) rotate(90deg)' }}
                        src={Arrow}
                        alt="Navigate Right" />
                </div>

            </Link>

        </div>
    )
}

ArticleNavigation.propTypes = {
    currentArticle: PropTypes.string.isRequired
}

export default ArticleNavigation
