import React from 'react'



import Card from '../general/Card'
import BotButton from '../landBot/BotButton'
const Article = props => {
    return (
        <Card 
            data-aos="fade-up" data-aos-duration="1000"
            className="lg:w-2/3 lg:mx-auto md:px-12 mb-24 mx-4 px-4 ">
            <h3 className="text-lg font-bold">Want to know more?</h3>
            <p>Let's chat! You can call us on +44 020 3770 4279 or email <a className="underline hover:pointer" href="mailto:hello@kontor.com">hello@kontor.com</a></p>
            <h3 className="text-lg font-bold">Thinking of moving office? </h3>
            <p>We'd love to help you smash this awesome milestone.</p>

            <div
                className="text-center relative w-full ">
                <BotButton
                    type="secondary"
                    style={{padding: '0.25rem'}}
                    className="lg:w-1/2 w-4/5 text-center mx-auto inline-block absolute lg:left-1/4 left-1/10"
                >LET'S GET STARTED</BotButton>

            </div>

        </Card>

    )
}

Article.propTypes = {

}

export default Article
