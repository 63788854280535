import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby';

const EmbeddedEntry = props => {
    const { title, link } = props;

    return (
        <Link 
            
            to={`/articles/${link}`}>
                <div
                    className="p-6 hover:shadow-lg shadow-sm border-2 border-secondary transition-quick"
                >
                    <h3 className="text-xl text-primary" >{title}</h3>
                </div>
            
        </Link>
    )
}

EmbeddedEntry.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
}

export default EmbeddedEntry
